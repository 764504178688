/* eslint-disable import/no-unresolved */
import Vue from 'vue'
import Toast from 'vue-toastification'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './i18n'

// using AOS
import 'aos/dist/aos.css'

// Import Style Bundle
require('@/assets/css/vendor.bundle.css')
require('@/assets/css/style-light.css')
require('@/assets/css/style-dark.css')
require('vue-toastification/dist/index.css')

const toastOptions = {
  position: 'top-right',
  timeout: 8000,
  closeOnClick: false,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: false,
  rtl: false,
  maxToasts: 4,
  newestOnTop: true,
}

Vue.use(Toast, toastOptions)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
