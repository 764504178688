<template>
  <div
    id="body"
    class="h-100 nk-body body-wider mode-onepage"
  >
    <router-view />
    <!-- <div class="preloader" ref="preloader">
      <span class="spinner spinner-round" ref="spinner" />
    </div> -->
  </div>
</template>

<script>
import AOS from 'aos'
import smoothscroll from 'smoothscroll-polyfill'
// import store from '@/store/index'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      theme: 'landing/getTheme',
    }),
  },
  watch: {
    theme(newValue, oldValue) {
      if (newValue !== oldValue) {
        const bodyTheme = document.querySelector('body')
        if (newValue === 'dark') {
          bodyTheme.classList.add('dark-mode')
        } else {
          bodyTheme.classList.remove('dark-mode')
        }
      }
    },
  },
  created() {
    AOS.init({
      duration: 1000,
      once: true,
    })
    smoothscroll.polyfill()
  },
  mounted() {
    if (localStorage.lang !== null) this.$i18n.locale = localStorage.lang
    if (!localStorage.getItem('theme')) {
      localStorage.setItem('theme', 'dark')
    }
    if (this.theme === 'dark') {
      document.querySelector('body').classList.add('dark-mode')
    } else {
      document.querySelector('body').classList.remove('dark-mode')
    }
    // if (this.$refs.preloader) {
    //   setTimeout(() => {
    //     this.$refs.body.classList.value = `${this.$refs.body.classList.value} page-loaded`
    //     this.$refs.spinner.classList.value = `${this.$refs.spinner.classList.value} load-done`

    //     if (!this.$refs.spinner.classList.contains('spinner-alt')) {
    //       this.$refs.spinner.classList.toggle('fade')
    //     }
    //     this.$refs.preloader.classList.toggle('fade')
    //   }, 1500)
    // }
  },
  methods: {
  },
}

</script>

<style>
.spinner {
  opacity: 1;
  transition: opacity 3s;
  animation-delay: 3s;
}

.spinner.fade {
  opacity: 0;
  display: none !important;
}
</style>
