import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    lang: localStorage.getItem('lang') || 'id',
    toggleShow: false,
    goTop: false,
    rocketDuration: null,
    preferedTheme: localStorage.getItem('theme') || 'dark',
  },

  getters: {
    getLanguage: state => state.lang,
    getToggle: state => state.toggleShow,
    getRocket: state => state.goTop,
    getRocketDuration: state => state.rocketDuration,
    getTheme: state => state.preferedTheme,
  },

  mutations: {
    set_toggle(state, data) {
      state.toggleShow = data
    },
    set_rocket(state, data) {
      state.goTop = data
    },
    set_rocket_duration(state, data) {
      state.rocketDuration = data
    },
    set_prefered_language(state, data) {
      state.lang = data
    },
    set_prefered_theme(state, data) {
      state.preferedTheme = data
    },
  },

  actions: {
    async CHANGE_TOGGLE({ commit }, data) {
      // eslint-disable-next-line no-useless-catch
      try {
        commit('set_toggle', data)
      } catch (error) {
        throw error
      }
    },

    async GO_TOP({ commit }, data) {
      // eslint-disable-next-line no-useless-catch
      try {
        commit('set_rocket', data)
      } catch (error) {
        throw error
      }
    },

    async ROCKET_DURATION({ commit }, data) {
      // eslint-disable-next-line no-useless-catch
      try {
        commit('set_rocket_duration', data)
      } catch (error) {
        throw error
      }
    },

    async CHANGE_LANGUAGE({ commit }, data) {
      // eslint-disable-next-line no-useless-catch
      try {
        localStorage.setItem('lang', data)
        i18n.locale = data
        commit('set_prefered_language', data)
      } catch (error) {
        throw error
      }
    },

    async CHANGE_THEME({ commit }, data) {
      // eslint-disable-next-line no-useless-catch
      try {
        localStorage.setItem('theme', data)
        commit('set_prefered_theme', data)
      } catch (error) {
        throw error
      }
    },

    async SCROLL_PAGE({ dispatch }, data) {
      // eslint-disable-next-line no-useless-catch
      try {
        if (this.hash !== '') {
          data.event.preventDefault()
        }
        return new Promise((resolve, reject) => {
          const element = document.scrollingElement

          if (typeof data.to === 'string') {
            // eslint-disable-next-line no-param-reassign
            data.to = document.querySelector(data.to) || reject()
          }
          if (typeof data.to !== 'number') {
            // eslint-disable-next-line no-param-reassign
            data.to = data.to.getBoundingClientRect().top + element.scrollTop
          }

          const start = element.scrollTop
          const change = data.to - start
          let currentTime = 0
          const increment = 40
          const duration = (Math.abs(change) / 3) + 400

          const animateScroll = async () => {
            currentTime += increment
            const val = await dispatch('landing/GET_SCROLL_TIME', {
              currentTime, start, change, duration,
            }, { root: true })
            element.scrollTop = val
            if (currentTime < duration) {
              setTimeout(animateScroll, increment)
            } else {
              resolve()
            }
          }
          animateScroll()
        })
      } catch (error) {
        throw error
      }
    },

    GET_SCROLL_TIME(commit, data) {
      let t = data.currentTime
      const b = data.start
      const c = data.change
      const d = data.duration
      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      // eslint-disable-next-line no-param-reassign
      t /= d / 2
      const subs = c / 2
      if (t < 1) {
        return subs * t * t * t + b
      }
      // eslint-disable-next-line no-param-reassign
      t -= 2
      return subs * (t * t * t + 2) + b
    },
  },
}
