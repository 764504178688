import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import landing from './landing'

Vue.use(Vuex)

const strictMode = (process.env.NODE_ENV !== 'production')

export default new Vuex.Store({
  modules: {
    landing,
  },
  strict: strictMode,
})
